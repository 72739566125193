<template>
  <div>
    <v-dialog v-model="popupShow" width="500">
      <v-card>
        <div class="text-right">
          <v-btn color="gray darken-1" text @click="popupShow = close()">
            <b-icon icon="x" scale="2" variant="Secondary"></b-icon>
          </v-btn>
        </div>
        <div class="text-center pt-5 pb-5">
          <b-img
            src="/image/FeedView-Logo-01.svg"
            width="200"
            alt="Circle image"
          ></b-img>
        </div>
        <div v-if="!startQ">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            <b-icon icon="person-fill"></b-icon> Quick Company Register
          </div>
          <div class="text-center mt-5 pb-2">
            <v-btn
              color="grey darken-2"
              class="mr-4 text-white"
              @click="startQuestion()"
            >
              Register
            </v-btn>
          </div>
        </div>
        <div v-if="step == 1 && startQ">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div>
                <v-text-field
                  v-model="company_name"
                  label="Company Name"
                  required
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col> </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="text-white"
                      @click="stepchange(2, company_name)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 2">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div>
                <v-text-field
                  v-model="company_email"
                  label="Company email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white"
                      @click="stepback(2)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="text-white"
                      @click="stepchange(3, company_email)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 3">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mt-5 mb-5 pb-3">
                <v-select
                  label="Project Size"
                  v-model="project_size"
                  dense
                  hide-details
                  :items="pro_size"
                  :counter="10"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-left"
                      @click="stepback(3)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="text-white text-right"
                      @click="stepchange(4, project_size)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 4">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mt-5 mb-5 pb-3">
                <v-select
                  label="Hourly Rate"
                  v-model="rate"
                  dense
                  hide-details
                  :items="Hourly_Rate"
                  :counter="10"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
                <span v-if="error_msg != null" style="color: red"
                  >*{{ error_msg }}</span
                >
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-left"
                      @click="stepback(4)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="text-white text-right"
                      @click="stepchange(5, rate)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 5">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mt-5 mb-5 pb-3">
                <v-select
                  label="Company Size"
                  v-model="size"
                  dense
                  hide-details
                  :items="Company_Size"
                  :counter="10"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-left"
                      @click="stepback(5)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-right"
                      @click="stepchange(6, size)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 6">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div>
                <v-text-field
                  v-model="company_founded"
                  class="pb-0"
                  label="Company Founded"
                  required
                  :rules="YearRules"
                ></v-text-field>
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-left"
                      @click="stepback(6)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-right"
                      @click="stepchange(8, company_founded)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>

        <div v-if="step == 8">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div>
                <v-text-field
                  v-model="company_summary"
                  label="Summary"
                  required
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
              </div>
              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-left"
                      @click="stepback(7)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-right"
                      @click="stepchange(9, company_summary)"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 9">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            {{ q1 }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mt-5 mb-5 pb-3">
                <v-select
                  label="Company Category"
                  v-model="company_category"
                  dense
                  multiple
                  item-text="name"
                  item-value="name"
                  hide-details
                  :items="company_cat"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
                <span v-if="error_msg != null" style="color: red"
                  >*{{ error_msg }}</span
                >
              </div>

              <div class="mt-2">
                <v-row>
                  <v-col>
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-left"
                      @click="stepback(9)"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      color="grey darken-2"
                      class="mr-4 text-white text-right"
                      @click="stepchange(10, company_category)"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="step == 10">
          <div
            class="text-h5 p-2 grey darken-3 text-white lighten-2 text-center"
          >
            Thank you for creating company!!!
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ComoanyPopup",
  props: ["popupShow"],
  data: function () {
    return {
      dispaly: false,
      q1: "",
      dialog: false,
      step: 1,
      startQ: false,
      stop: false,
      valid: true,
      message: "",
      msg: false,
      open: false,
      pro_size: [],
      Hourly_Rate: [],
      Company_Size: [],
      company_cat: [],
      company_name: "",
      company_email: "",
      project_size: "",
      rate: "",
      company_founded: "",
      size: "",
      company_tagline: "",
      company_summary: "",
      company_category: "",
      cat_percentage: null,
      companyNameRules: [(v) => !!v || "Cpmpany Name is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      YearRules: [
        (v) => !!v || "Founded Year is required",
        (v) => (v && v <= 2021) || "Year is Invalidate",
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      error_msg: null,
    };
  },

  mounted() {
    console.log();
    this.fetchdata();
    //console.log(this.popupShow);
  },
  methods: {
    ...mapActions([
      "fetchMinSizeProject",
      "fetchHourlyRate",
      "fetchCompanySize",
      "sendQuickCreateCompanyData",
      "getCompanyCategory",
      "sendCategory",
    ]),
    validate() {
      this.$refs.form.validate();
    },
    fetchdata() {
      this.fetchMinSizeProject().then((response) => {
        response.data.data.forEach((value) => {
          this.pro_size.push(value.name);
        });
      });
      this.fetchHourlyRate().then((response) => {
        response.data.data.forEach((value) => {
          this.Hourly_Rate.push(value.name);
        });
      });
      this.fetchCompanySize().then((response) => {
        response.data.data.forEach((value) => {
          this.Company_Size.push(value.name);
        });
      });
      this.getCompanyCategory().then((response) => {
        response.data.data.forEach((value) => {
          if (value["parent_id"] == 0) {
            let s_id = value["id"];

            response.data.data.forEach((v) => {
              if (v["parent_id"] == s_id) {
                let detail = {};
                detail.name = v["name"];
                detail.id = v["id"];

                this.company_cat.push(detail);
              }
            });
          }
        });
      });
    },
    typeWriter() {
      if (this.step == 1) {
        this.q1 = "";
        this.q1 = "What is your Company Name?";
      } else if (this.step == 2) {
        this.q1 = "";
        this.q1 = "What is your Company Email?";
      } else if (this.step == 3) {
        this.q1 = "";
        this.q1 = "What is your Company Project size?";
      } else if (this.step == 4) {
        this.q1 = "";
        this.q1 = "What is your Company Hourly Rate?";
      } else if (this.step == 5) {
        this.q1 = "";
        this.q1 = "What is Your Company Size?";
      } else if (this.step == 6) {
        this.q1 = "";
        this.q1 = "When Your Company Founded?";
      } else if (this.step == 7) {
        this.q1 = "";
        this.q1 = "What is your Company Tagline?";
      } else if (this.step == 8) {
        this.q1 = "";
        this.q1 = "Summary";
      } else if (this.step == 9) {
        this.q1 = "";
        this.q1 = "Select Company Category";
      }
    },
    startQuestion() {
      if (localStorage.getItem("user")) {
        this.startQ = true;
        var x = this;
        x.open = true;
        x.typeWriter();
      } else {
        this.popupShow = false;
        this.$router.push({ name: "login" });
      }
    },
    stepchange(step) {
      this.message = "";

      let validate = this.$refs.form.validate();
      if (validate) {
        this.step = step;
        this.$refs.form.resetValidation();
        if (this.step != 10) {
          this.message = "";
          this.typeWriter();
        }
      }
      if (this.step == 10) {
        this.sendQuickCreateCompanyData({
          company_name: this.company_name,
          min_project_size: this.project_size,
          primary_email: this.company_email,
          hourly_rate: this.rate,
          company_size: this.size,
          founded_year: this.company_founded,
          tag_line: this.company_tagline,
          summary: this.company_summary,
        }).then((response) => {
          console.log(response);
          let id = response.data.data.id;
          let percentage = parseInt(100 / this.company_category.length);
          let cat_object = [];
          this.company_category.forEach((x) => {
            let obj = {};
            obj.company_id = id;
            obj.category_id = x;
            obj.percentage = percentage;
            cat_object.push(obj);
          });
          console.log(cat_object);
          this.sendCategory(cat_object).then((response) => {
            console.log(response);
          });
        });
      }
    },
    stepback(step) {
      if (step != 1) {
        this.message = "";
        this.step = step - 1;
        this.typeWriter();
      } else {
        this.message = "";
        this.startQ = false;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    close() {
      this.$emit("close", { popupShow: false });
    },
  },
};
</script>

<style scoped>
.maincolor {
  background-color: 242b34;
}
</style>