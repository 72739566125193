import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Vuetify from 'vuetify'
import moment from 'moment';
import 'vuetify/dist/vuetify.min.css'
import LoadScript from 'vue-plugin-load-script'
import '@mdi/font/css/materialdesignicons.css'

import { BootstrapVue, BootstrapVueIcons, LayoutPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

Vue.use(LoadScript)
Vue.use(BootstrapVue)
Vue.use(LayoutPlugin)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false;

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('D MMMM YYYY')
  }
});

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 422) {
//       store.commit("setErrors", error.response.data.message);
//     } else if (error.response.status === 401) {
//       store.commit("setUserData", null);
//       localStorage.removeItem("authToken");
//       router.push({ name: "Login" });
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );
axios.interceptors.request.use(function (config) {
  config.headers.common['Access-Control-Allow-Origin'] = '*';
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  return config;
});


Vue.use(Vuetify);



new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount("#app");
