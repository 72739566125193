import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        errors: [],
        user: null,
    },

    getters: {
        user: state => {
            return state.user
        },
        errors: state => state.errors,
        isAuthenticated: state => !!state.user,
        StateUser: state => state.user
    },

    mutations: {
        setErrors(state, errors) {
            state.errors = errors;
        },
        setUserData: (state, user) => {
            state.user = user;
        },
    },

    actions: {
        setUserData: async({ commit }, request) => {
            let { data } = await axios
                .post(process.env.VUE_APP_API_URL + "getUserById", request);
            localStorage.setItem("user", JSON.stringify(data.data));
            localStorage.setItem("authToken", data.data.remember_token);
            commit("setUserData", data.data);
            if (data) {
                return data;
            }
        },

        getUserData: async({ commit }) => {
            let { data } = await axios
                .get(process.env.VUE_APP_API_URL + "getUser");
            localStorage.setItem("authToken", data.data.remember_token);
            localStorage.setItem("user", JSON.stringify(data.data));
            commit("setUserData", data.data);
            if (data) {
                return data;
            }
        },

        getUserFromStore({ commit }) {
            commit("setUserData", localStorage.getItem("user"));
        },

        sendLoginWithLinkedIn() {
            return axios
                .get(process.env.VUE_APP_API_URL + "linkedin");
        },

        // getDataWithLinkedIn() {
        //     return axios
        //         .get(process.env.VUE_APP_API_URL + "linkedin/callback");
        // },

        sendLogoutRequest: async({ commit }) => {
            commit("setUserData", null);
            localStorage.removeItem("authToken");
            localStorage.removeItem("slug");
            localStorage.removeItem("user")
            localStorage.removeItem("popupshowkey")
        },
        /* find Company Page */
        getCompanyCategory() {
            return axios
                .get(process.env.VUE_APP_API_URL + "category/list");
        },

        /* List Company Page */
        getCompanyDetail({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "companylist/" + request);
        },

        /* List Company Page */
        getCompanySearch({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios
                .post(process.env.VUE_APP_API_URL + "companysearch", request);
        },



        /* profile Company Page */
        getCompanyProfile({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "companydetails?slug=" + request);
        },
        /* Blog Page */
        getBlog({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getblogs");
        },
        /* Review Page */
        getReview({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "sendreview", request)

        },

        getUserReview({ commit }, request) {
            commit("setErrors", {}, { root: true });

            return axios.get(process.env.VUE_APP_API_URL + "getuserreview/" + request)

        },

        getCostList({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getProjectCostList");
        },

        /* Blog detail Page */
        async getBlogDetail({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return await axios.post(process.env.VUE_APP_API_URL + "blogdetail", request)

        },

        // Add Blog Details

        getaddblog({ commit }, request) {

            commit("setErrors", {}, { root: true });
            let data = new FormData();
            let file = request.banner;
            let file1 = request.attachment;
            data.append('title', request.title)
            data.append('postdate', request.postdate)
            data.append('short_desc', request.short_desc)
            data.append('user_id', request.user_id)
            data.append('Banner', file)
            data.append('attachment', file1)
            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            console.log(data);
            return axios.post(process.env.VUE_APP_API_URL + "addblogdetails", data, config)

        },
        /* create company api */
        // create company data submit

        sendCreateCompanyData({ state, commit }, request) {
            commit("setErrors", {}, { root: true });
            let data = new FormData();
            let file = request.company_logo;
            data.append('company_logo', file)
            data.append('user_id', state.user.id)
            data.append('company_name', request.company_name)
            data.append('min_project_size', request.min_project_size)
            data.append('hourly_rate', request.hourly_rate)
            data.append('company_size', request.company_size)
            data.append('founded_year', request.founded_year)
            data.append('tag_line', request.tag_line)
            data.append('summary', request.summary)

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            return axios.post(process.env.VUE_APP_API_URL + "createcompany", data, config)
        },
        //update company data submit
        sendUpdateCompanyData({ state, commit }, request) {
            commit("setErrors", {}, { root: true });
            // if (request.apge == 3) {
            //     let data = new FormData();
            //     let file = request.companydata.basicInfo.company_logo;
            //     data.append('company_logo', file)
            //     data.append('user_id', state.user.id)
            //     data.append('company_name', request.companydata.basicInfo.company_name)
            //     data.append('min_project_size', request.companydata.basicInfo.min_project_size)
            //     data.append('hourly_rate', request.companydata.basicInfo.hourly_rate)
            //     data.append('company_size', request.companydata.basicInfo.company_size)
            //     data.append('founded_year', request.companydata.basicInfo.founded_year)
            //     data.append('tag_line', request.companydata.basicInfo.tag_line)
            //     data.append('summary', request.companydata.basicInfo.summary)
            //     data.append('website', request.companydata.leadsInfo.website)
            //     data.append('primary_email', request.companydata.leadsInfo.primary_email)
            //     let config = {
            //         header: {
            //             'Content-Type': 'multipart/form-data'
            //         }
            //     }
            //     return axios.post(process.env.VUE_APP_API_URL + "updatecompany", data, config)
            // } else if (request.page == 6) {

            let data = new FormData();

            if (request.basicInfo.company_logo) {
                let file = request.basicInfo.company_logo;
                data.append('company_logo', file)
            }
            data.append('user_id', state.user.id)
            data.append('company_name', request.basicInfo.company_name)
            data.append('min_project_size', request.basicInfo.min_project_size)
            data.append('hourly_rate', request.basicInfo.hourly_rate)
            data.append('company_size', request.basicInfo.company_size)
            data.append('founded_year', request.basicInfo.founded_year)
            data.append('tag_line', request.basicInfo.tag_line)
            data.append('summary', request.basicInfo.summary)
            if (request.leadsInfo) {
                data.append('website', request.leadsInfo.website)
                data.append('primary_email', request.leadsInfo.primary_email)
            }
            if (request.adminInfo) {
                data.append('admin_email', request.adminInfo.admin_email)
                data.append('admin_phone_number', request.adminInfo.admin_phone_number)
                data.append('linked_in_url', request.adminInfo.facebook_url)
                data.append('facebook_url', request.adminInfo.linked_in_url)
                data.append('twitter_url', request.adminInfo.twitter_url)
            }
            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            return axios.post(process.env.VUE_APP_API_URL + "updatecompany", data, config)



            //}
        },
        // add portfolio of company
        addCompanyPortfolio({ state, commit }, request) {
            commit("setErrors", {}, { root: true });
            // console.log(request);
            if (state.user.company_slug) {
                let value = request;
                let data = new FormData();
                if (value.imgbinary) {
                    let file = value.imgbinary;
                    data.append('image', file);
                }
                data.append('company_id', state.user.company_slug.id)
                data.append('video_url', null)
                data.append('project_category', value.project_category)
                data.append('discription', value.description)
                data.append('title', value.title)
                data.append('project_size', value.project_size)
                let config = {
                    header: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                axios.post(process.env.VUE_APP_API_URL + "addProtfoilio", data, config)
            }
            return true;

        },

        // remove portfolio of company
        removeCompanyPortfolio({ commit }, request) {
            commit("setErrors", {}, { root: true });
            axios.post(process.env.VUE_APP_API_URL + "removeProtfoilio", request)

            return true;

        },
        // create company address submit

        sendAddressDate({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "createaddress", request);

        },
        // Function for get min size of project

        fetchMinSizeProject() {
            return axios.get(process.env.VUE_APP_API_URL + "getProjectSizeList");
        },

        // Function for get Employee Count List

        fetchEmployeeCount({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getEmployeeCountList");
        },

        // Function for get Employee Count List

        getClientFocus({ commit }, companyId) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getClientFocus?company_id=" + companyId);
        },

        // Function for get Employee Count List

        getIndustryFocus({ commit }, companyId) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getIndustryFocus?company_id=" + companyId);
        },

        // Function for get Employee Count List

        getSpecializationsFocus({ commit }, companyId) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getSpecializationsFocus?company_id=" + companyId);
        },

        // Function for get Employee Count List

        getServiceLineFocus({ commit }, data) {
            console.log(data.type)
            commit("setErrors", {}, { root: true });
            if (typeof data.company_id !== 'undefined') {
                return axios
                    .get(process.env.VUE_APP_API_URL + "getServiceLineFocus?company_id=" + data.company_id + "&page_name=" + data.type);
            }
        },



        // Function for get company project list

        fetchDataDropdown({ commit }) {
            commit("setErrors", {}, { root: true });

            return axios
                .get(process.env.VUE_APP_API_URL + "getProjectSizeList");
        },



        // Function for get company Size list

        fetchCompanySize({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getCompanySizeList");
        },

        // Function for get company Hourly Rate list

        fetchHourlyRate({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getHourlyRateList");
        },

        // Function for get Specialization list
        getSpecialization({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getSpecializations");
        },

        // Function for get Client list
        getClientList({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getClientSize");
        },

        // Function for get Client list
        getIndustryList({ commit }) {
            commit("setErrors", {}, { root: true });
            return axios
                .get(process.env.VUE_APP_API_URL + "getIndustryFocusList");
        },

        sendSpecialization({ commit }, request) {
            commit("setErrors", {}, { root: true });
            let data = new FormData();
            data.append('data', request)

            return axios.post(process.env.VUE_APP_API_URL + "addSpecialization", request)
        },

        sendClientSize({ commit }, request) {
            commit("setErrors", {}, { root: true });
            let data = new FormData();
            data.append('data', request)

            return axios.post(process.env.VUE_APP_API_URL + "addClientSize", request)
        },

        sendIndustryFocus({ commit }, request) {
            commit("setErrors", {}, { root: true });
            let data = new FormData();
            data.append('data', request)

            return axios.post(process.env.VUE_APP_API_URL + "addIndustryFocus", request)
        },

        sendCategory({ commit }, request) {
            commit("setErrors", {}, { root: true });
            let data = new FormData();
            console.log(request);
            data.append('data', request)

            return axios.post(process.env.VUE_APP_API_URL + "addServiceLine", request)
        },
        //add this in store
        sendQuickCreateCompanyData({ commit }, request) {
            commit("setErrors", {}, { root: true });
            let data = new FormData();
            // let file = request.company_logo;
            // data.append('company_logo', file)
            data.append('user_id', 4)
            data.append('company_name', request.company_name)
            data.append('min_project_size', request.min_project_size)
            console.log(request.primary_email)
            data.append('primary_email', request.primary_email)
            data.append('hourly_rate', request.hourly_rate)
            data.append('company_size', request.company_size)
            data.append('founded_year', request.founded_year)
            data.append('tag_line', request.tag_line)
            data.append('summary', request.summary)

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            return axios.post(process.env.VUE_APP_API_URL + "createcompany", data, config)
        },

        fetchTestimonialData() {
            return axios.get(process.env.VUE_APP_API_URL + "testimonialData")
        },

        sendQuestion({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "sendQuestion", request);
        },
        getQuestionAnswerList({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "getQuestionAnswerList", request);
        },
        sendContactUsData({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "contactus", request);
        },
        sendCompanyContactUsData({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "companycontactus", request);
        },
        searchCompanyByName({ commit }, request) {
            commit("setErrors", {}, { root: true });
            return axios.post(process.env.VUE_APP_API_URL + "searchCompanyByName", request);
        },
        getCountry() {

            return axios.get(process.env.VUE_APP_API_URL + "getCountry");
        },
        getCity() {

            return axios.get(process.env.VUE_APP_API_URL + "getCity");
        },





    },

});