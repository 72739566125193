<template>
  <v-app>
    <Header :linkedIn="setUser"></Header>
    <router-view></router-view>
    <Footer></Footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: function () {
    return {
      website: null,
      name: null,
      profile: null,
      items: [],
      Sort_By: null,
      rating: 3,
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      company_name: null,
      company_webiste: null,
      industry: null,
      headquater_city: null,
      company_size: null,
      job_title: null,
      project_title: null,
      cost_range: null,
      date: null,
      date1: null,
      updatedDate: null,
      menu: null,
      menu1: null,
      companySlug: null,
      setUser: null,
    };
  },
  mounted() {
    setInterval(() => {
      this.setUser = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
    });
  },

  created() {
    const unwatch = this.$watch(
      () => this.$route,
      (route) => {
        this.activeIndex = route.name;
        unwatch();
      }
    );
  },

  methods: {
    ...mapActions(["setUserData"]),

    getUserById() {
      let data = { id: this.$route.params.id };
      this.setUserData(data).then((response) => {
        this.email = response.data.email;
        this.name =
          response.data.userdetails.first_name +
          " " +
          response.data.userdetails.last_name;
        this.profile = response.data.userdetails.profile_picture;
        this.company_name = response.data.company_slug.company_name;
        this.website = response.data.company_slug.website;
        this.updatedDate = response.data.company_slug.updated_at;
        this.companySlug = response.data.company_slug.slug;
      });
    },
  },
};
</script>


