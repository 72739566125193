<template>
  <div class="lightColor">
    <b-row class="ml-lg-15 ml-5 mr-5 mr-lg-15 mb-1">
      <b-col cols="12" lg="10" class="pr-lg-0 pb-0 pt-0">
        <b-form class="d-flex mt-2 float-lg-end float-sm-end">
          <v-text-field
            hide-details
            placeholder="Search"
            filled
            rounded
            dense
            append-icon="mdi-magnify"
            class="bg-transparent"
          >
          </v-text-field>
          <!-- </b-input-group> -->
        </b-form>
      </b-col>
     
      <b-col
        cols="12"
        lg="2"
        class="text-center d-none d-md-block d-lg-block pt-2 pb-0 pl-lg-0"
        ><b-button
          class="outline-dark border-radius-8 p-1 pr-4 pl-4 font-size-18"
          @click="showPopup"
          :disabled="linkedIn && linkedIn['company_id'] ? true : false"
        >
          Quick Register
        </b-button>
      </b-col>
    </b-row>
    <popup :popupShow="popupShow" @close="showPopup"></popup>
    <b-navbar toggleable="lg" type="dark" class="pt-2 pt-sm-0 pt-md-0 pt-lg-0 ">
      <b-row class="navbar-margin ml-lg-15 ml-5 mr-5 mr-lg-15 mb-1 pt-0">
        <b-col class="text-left logo pt-0 mt-0" cols="12" lg="3" sm="12">
          <b-navbar-brand to="/">
            <b-img
              src="/image/FeedView-Logo-01.svg"
              height="50"
              alt="Circle image"
              class="mr-5"
            ></b-img
          ></b-navbar-brand>
          <b-navbar-toggle
            class="float-end mt-sm-2 mt-2"
            target="nav-collapse"
          ></b-navbar-toggle>
        </b-col>

        <b-col cols="12" lg="9" class="mb-0 pt-0" sm="12">
          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/" class="m-auto mr-4 font-size-20 font-weight"
                >Home</b-nav-item
              >
              <b-nav-item
                to="/listcompany"
                class="m-auto mr-4 font-size-20 font-weight"
                >Companies</b-nav-item
              >
              <b-nav-item
                to="/blog"
                class="m-auto mr-4 font-size-20 font-weight"
                >Blog</b-nav-item
              >
              <b-nav-item
                to="/aboutus"
                class="m-auto mr-4 font-size-20 font-weight"
                >About Us</b-nav-item
              >
              <b-nav-item
                to="/login"
                class="m-auto mr-4 font-size-20 font-weight"
                v-if="!isLoggedIn"
                >Login</b-nav-item
              >
              <b-nav-item
                to="/signup"
                class="m-auto mr-4 font-size-20 font-weight"
                v-if="!isLoggedIn"
                >Sign Up</b-nav-item
              >
              <!-- <b-nav-item
                ><router-link class="pr-0" to="/listcompany"
                  ><b-button
                    class="
                      button-bg-dark
                      border-radius-8
                      font-size-18
                      float-end
                    "
                  >
                    List Of Company
                  </b-button></router-link
                ></b-nav-item
              > -->
              <b-nav-item class="d-lg-none">
                <div class="pr-0 float-right">
                  <b-button
                    class="
                      button-bg-dark
                      border-radius-8
                      p-1
                      pr-5
                      pl-6
                      font-size-18
                    "
                    :disabled="
                      linkedIn && linkedIn['company_id'] ? true : false
                    "
                    @click="showPopup"
                  >
                    Quick Register
                 
                  </b-button>
                </div>
              </b-nav-item>
              <b-nav-item-dropdown
                v-if="isLoggedIn"
                class="userDropdown"
                id="my-nav-dropdown"
                toggle-class="nav-link-custom"
                right
              >
                <template slot="button-content"
                  ><b-avatar
                    :src="
                      isLoggedIn &&
                      isLoggedIn.userdetails &&
                      isLoggedIn.userdetails.profile_picture
                        ? isLoggedIn.userdetails.profile_picture
                        : ''
                    "
                  ></b-avatar
                ></template>
                <b-dropdown-item :to="'/dashboard/' + isLoggedIn.linkedin_id">
                  DashBoard</b-dropdown-item
                >
                <b-dropdown-item @click="Logout">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-col>
      </b-row>
    </b-navbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import popup from "../components/CompanyRegisterPopup.vue";
export default {
  components: { popup },
  props: ["linkedIn"],
  name: "Header",

  data: function () {
    return {
      profileId: null,
      profile: null,
      userId: null,
      popupShow: false,
      login_id: null,
      user: null,
      search: null,
     
    };
  },

  computed: {
    isLoggedIn: function () {
      return this.linkedIn;
    },
  },
 
 

  mounted() {
    this.user = localStorage.getItem("user");
    
   
    
    if (this.isLoggedIn) {
      this.getUser();
    }
    if (this.$route.params.id) {
      this.login_id = this.$route.params.id;
    }
  },

  methods: {
    ...mapActions([
      "getUserFromStore",
      "getUserData",
      "setUserData",
      "sendLogoutRequest",
    ]),
    findSearchData() {
      console.log("hello", this.search);
      if (window.location.pathname != "/listcompany") {
        this.$router.push("/listcompany");
        localStorage.setItem("searchdata", this.search);
      } else {
        localStorage.setItem("searchdata", this.search);
      }
    },
    Dashboard() {
      this.$router.push("/dashboard/" + this.login_id);
    },
    showPopup() {
      this.popupShow = !this.popupShow;
    },

    goToListCompany() {
      this.$router.push("/listcompany");
    },
    getUserById() {
      if (this.$route.params.id) {
        let data = { id: this.$route.params.id };
        this.setUserData(data).then(() => {});
      }
    },
    getUser() {
      this.getUserData().then((res) => {
        this.userId = res.data.id;
        this.profileId = res.data.linkedin_id;
        this.profile = res.data.userdetails.profile_picture;
      });
    },
    Logout() {
      let data = { id: this.$route.params.id };
      this.sendLogoutRequest(data).then(() => {
        this.$router.push("/");
      
      });
     
    },
  },
};
</script>
<style >
.userDropdown a#my-nav-dropdown__BV_toggle_ {
  background-color: transparent !important;
  margin-left: 9px;
}
</style>