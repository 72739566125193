<template>
  <div class=" ">
    <footer class="lightColor text-lg-left">
      <div
        class="
          row
          ml-lg-15 ml-md-5
          mr-lg-12
          w-sm-100
          mx-0
          align-items-sm-center
          flex-md-row flex-lg-row flex-sm-column
        "
      >
        <b-col
          cols="12"
          sm="7"
          class="
            pl-3
            text-center text-lg-left text-md-left
            pt-md-5 pt-10 pt-lg-5
          "
        >
          <b-navbar-brand to="/" class="mr-sm-0 mr-0"
            ><b-img
              src="/image/FeedView-Logo-01.svg"
              height="50"
              alt="Circle image"
            ></b-img
          ></b-navbar-brand>
        </b-col>
        <b-col
          cols="12"
          sm="5"
          class="
            pt-6
            pr-lg-8
            text-center
            d-flex
            flex-column
            d-md-inline-block
            d-lg-inline-block
            text-lg-right
          "
        >
          <router-link class="pb-5" to="/findcompany"
            ><b-button class="mr-md-4 mr-lg-4 button-bg-dark font-size-20"
              >Find Companies</b-button
            ></router-link
          >
          <router-link to="/getlisted"
            ><b-button class="outline-dark font-size-20"
              >Get Listed</b-button
            ></router-link
          >
        </b-col>
      </div>
      <div
        class="
          row
          ml-lg-15 ml-md-4
          mr-md-3 mr-lg-12
          mt-3
          mx-0
          align-items-sm-center
          flex-md-row flex-lg-row flex-sm-column
        "
      >
        <b-col cols="12"  md="9" lg="9" class="pl-5 pl-sm-5">
          <b-nav
            class="
              pl-0
              text-center
              d-flex
              flex-column flex-lg-row flex-md-row
              text-md-left text-lg-left
            "
          >
            <b-nav-item to="/" class="font-size-18 font-weight"
              >Home
            </b-nav-item>
            <b-nav-item to="/aboutus" class="font-size-18 font-weight"
              >About Us</b-nav-item
            >
            <b-nav-item to="/contactus" class="font-size-18 font-weight"
              >Contact Us</b-nav-item
            >
            <b-nav-item to="/termscondition" class="font-size-18 font-weight"
              >Terms & Conditions</b-nav-item
            >
            <b-nav-item to="/privacypolicy" class="font-size-18 font-weight"
              >Privacy Policy</b-nav-item
            >
            <b-nav-item :to="go" v-on:mouseover="gotoaddblog()" class="font-size-18 font-weight"
              >Write To Us</b-nav-item
            >
          </b-nav>
        </b-col>
        <b-col
          cols="12"
          md="3"
          lg="3"
          class="pr-0 pr-md-5 pr-lg-5 text-center text-md-right text-lg-right "
        >
          <b-nav class="pr-0 pl-md-0 d-inline-flex flex-sm-nowrap pl-sm-0 pl-0">
            <b-nav-item href="#" class="font-size-20"
              ><b-icon icon="facebook"></b-icon
            ></b-nav-item>
            <b-nav-item href="#" class="font-size-20"
              ><b-icon icon="linkedin"></b-icon
            ></b-nav-item>
            <b-nav-item href="#" class="font-size-20"
              ><b-icon icon="twitter"></b-icon
            ></b-nav-item>
          </b-nav>
        </b-col>
      </div>
    </footer>
  </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
  name: "Footer",

  data: function () {
    return {
      userid: null,go:null
    };
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },

  mounted() {
    if (this.$route.params.id) {
      //this.getUserData();
    } else if (this.isLoggedIn) {
      // this.getUser();
    }
  },

  created() {},

  methods: {
    ...mapActions(["setUserData", "sendLogoutRequest"]),

    getUserData() {
      if (this.$route.params.id) {
        let data = { id: this.$route.params.id };
        this.setUserData(data).then((response) => {
          if (response.data.is_logged_in == 0) {
            window.location.href = "/";
          }
        });
      }
    },
  gotoaddblog()
  {
    console.log("hello");
    this.userid=localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):"";
    if(this.userid)
    {
      this.go='/addblog'
      //this.$router.push('/addblog');
    }
    if(!this.userid)
    {
         this.go='/login'
    //  this.$router.push('/login');
    }
  },
    Logout() {
      let data = { id: this.$route.params.id };
      this.sendLogoutRequest(data).then(() => {
        window.location.href = "/";
      });
      //
    },
  },
};
</script>
<style scoped>
.footer-section {
  background-color: #0dcaf0 !important;
}
</style>