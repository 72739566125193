import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ('../views/home/HomePage.vue')
    },
    {
        path: '/login',
        meta: {
            auth: false // A protected route
        },
        name: 'login',
        component: () =>
            import ('../views/login/LoginPage.vue')
    },
    {
        path: '/signup',
        meta: {
            auth: false // A protected route
        },
        name: 'signup',
        component: () =>
            import ('../views/login/LoginPage.vue')
    },
    {
        path: '/tellus',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/tellus/TellusPage.vue')
    },
    {
        path: '/aboutus',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/aboutus/AboutusPage.vue')
    },
    {
        path: '/listcompany',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/company/ListCompany.vue')
    },
    {
        path: '/listcompany/:id',
        meta: {
            auth: false // A protected route
        },
        name: 'listcompany',
        component: () =>
            import ('../views/company/ListCompany.vue')
    },
    {
        path: '/profile/:id',
        name: 'profile',
        meta: {
            auth: true // A protected route
        },
        component: () =>
            import ('../views/company/ProfileCompany.vue')
    },
    {
        path: '/blog',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/blog/blogPage.vue')
    },
    {
        path: '/blogdetail/:id',
        meta: {
            auth: false // A protected route
        },
        name: 'blogdetail',
        component: () =>
            import ('../views/blog/blogdetailPage.vue')
    },
    {
        path: '/addblog',
        meta: {
            auth: true // A protected route
        },
        name: 'addblog',
        component: () =>
            import ('../views/blog/AddBlog.vue')
    },
    {
        path: '/review/:id',
        meta: {
            auth: true // A protected route
        },
        name: 'review',
        component: () =>
            import ('../views/review/ReviewPage.vue')
    },
    {
        path: '/reference/:id',
        meta: {
            auth: true // A protected route
        },
        name: 'reference',
        component: () =>
            import ('../views/reference/Reference.vue')
    },

    {
        path: '/askquestion/:id',
        meta: {
            auth: true // A protected route
        },
        name: 'askquestion',
        component: () =>
            import ('../views/askquestion/AskQuestion.vue')
    },

    {
        path: '/findcompany/:name',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/company/FindCompany.vue')
    },
    {
        path: '/findcompany',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/company/FindCompany.vue')
    },
    {
        path: '/getlisted',
        meta: {
            auth: true // A protected route
        },
        name: 'getlisted',
        component: () =>
            import ('../views/company/CreateCompany.vue')
    },
    {
        path: '/other-getlisted',
        meta: {
            auth: true // A protected route
        },
        component: () =>
            import ('../views/company/CreateCompanyOther.vue')
    },

    {
        path: '/getlisted/:id',
        meta: {
            auth: true // A protected route
        },
        name: 'getlisted-details',
        component: () =>
            import ('../views/company/CreateCompany.vue')
    },
    {
        path: '/dashboard/:id',
        meta: {
            auth: true // A protected route
        },
        name: 'dashboard',
        component: () =>
            import ('../views/dashboard/Dashboard.vue')
    },
    {
        path: '/contactus',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/contactus/ContactUs.vue')
    },
    {
        path: '/termscondition',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/termscondition/TermsCondition.vue')
    },
    {
        path: '/privacypolicy',
        meta: {
            auth: false // A protected route
        },
        component: () =>
            import ('../views/privacypolicy/PrivarcypolicyPage.vue')

    },
    {
        path: '/contact-company/:id',
        meta: {
            auth: true // A protected route
        },
        name: 'contactcompany',
        component: () =>
            import ('../views/company/ContactCompany.vue')
    },
    {
        path: '/askquestion-detail',
        component: () =>
            import ('../views/askquestion/AskQuestion-Detail.vue')
    }

]


const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
});


// router.beforeEach((to, from, next) => {
//     // const publicPages = ['/profile/:id', '/listcompany/:id', '/contact-company/:id', '/askquestion/:id', '/review/:id', '/dashboard/:id', '/addblog', '/getlisted', '/getlisted/:id', '/other-getlisted', '/reference/:id'];
//     // let authRequired = publicPages.includes(to.name);

//     const loggedIn = localStorage.getItem('authToken');
//     console.log(loggedIn)
//     // trying to access a restricted page + not logged in
//     // redirect to login page
//     if ((to.name == 'profile' || to.name == 'contact-company' || to.name == 'askquestion' || to.name == 'review' || to.name == 'getlisted') && !loggedIn) {
//         return next('/login');
//     } else {
//         next();
//     }
// });

export default router